// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import QRCodePage from "./QRCodePage"; // Import halaman QR code
import Login from "./Login"; // Import halaman login
import Test from "./Test";
import PrivateRoute from "./PrivateRoute"; // Import PrivateRoute

function App() {
  return (
    <Router>
      <Routes>
        {/* Rute Login */}
        <Route path="/login" element={<Login />} />
        <Route path="/test" element={<Test />} />

        {/* Rute QR Code yang dilindungi */}
        <Route
          path="/qrcode"
          element={
            <PrivateRoute>
              <QRCodePage />
            </PrivateRoute>
          }
        />

        {/* Redirect semua rute lain ke Login */}
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
