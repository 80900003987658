import React, { useState, useEffect, useCallback } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie"; // Import Lottie
import * as animationData from "./loading.json"; // Import JSON file from Lottie
import "./index.css"; // Import custom CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faBuilding } from "@fortawesome/free-solid-svg-icons";
import FlipCountdown from "@rumess/react-flip-countdown"; // Import FlipCountdown
import { ToastContainer, toast, Flip } from "react-toastify"; // Import react-toastify
import "react-toastify/dist/ReactToastify.css"; // Import react-toastify CSS

// Lottie animation options
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData, // Use the imported animation JSON file
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const baseUrl = "https://dasar.premanto.lol/api/generate-qrcode"; //baru niih , nanti jangan lupa ganti domain ya
const apiKey = "LUD9gIkbxML2Hogha5z0fyQrwrkYeJejgpWFZ";

function QRCodePage() {
  const [qrCodeData, setQrCodeData] = useState("");
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [advanceMode, setAdvanceMode] = useState(false);
  const [ip, setIp] = useState("");
  // eslint-disable-next-line
  const navigate = useNavigate();
  const nama = localStorage.getItem("nama");

  // Function to fetch QR code data
  const fetchQRCodeData = useCallback(async () => {
    if (!ip) return; // Only fetch if IP is set

    try {
      toast.promise(
        fetch(baseUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Api-Key": apiKey,
            "ngrok-skip-warning": "true",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({ ip }),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            if (data.error) {
              setError(data.error);
              throw new Error(data.error); // Trigger error toast
            } else {
              const qrCodeString = data.qrCode;
              localStorage.setItem("advanceQR", qrCodeString);
              if (qrCodeString) {
                setQrCodeData(qrCodeString);
                setLoaded(true);
              } else {
                throw new Error("QR code data is missing"); // Trigger error toast
              }
            }
          }),
        {
          pending: "Mengambil data QR Code...",
          success: "QR Code berhasil diambil 👌",
          error: "Gagal mengambil QR Code 🤯",
        },
        {
          position: "top-center",
          autoClose: 2000,
        }
      );
    } catch (error) {
      setError(`Error: ${error.message}`);
    }
  }, [ip]);

  useEffect(() => {
    const mode = localStorage.getItem("advanceMode") === "true";
    setAdvanceMode(mode);

    if (ip) {
      fetchQRCodeData();
      const intervalId = setInterval(fetchQRCodeData, 30000);
      return () => clearInterval(intervalId);
    }
  }, [fetchQRCodeData, ip]);

  useEffect(() => {
    if (qrCodeData) {
      console.log("QR Code Data updated:", qrCodeData);
    }
  }, [qrCodeData]); // Log QR Code data whenever it updates

  const handleRadioChange = (ipAddress) => {
    setIp(ipAddress);
    setSeconds(30);
  };

  const handleQRCodeClick = () => {
    if (ip) {
      fetchQRCodeData(); // Refresh QR code berdasarkan IP saat ini
      setSeconds(30); // Reset countdown timer
    }
  };

  const handleLogout = () => {
    // toast.error(`Error`, {
    //   position: "top-center",
    // });
    localStorage.removeItem("isLoggedIn");
    navigate("/login");
  };

  const handleDirectPresence = async () => {
    const url = "https://dasar.premanto.lol/api/proxy";
    setIp("172.16.17.8");
    const userId = localStorage.getItem("advanceNik");
    const password = localStorage.getItem("advancePassword");
    const deviceId = localStorage.getItem("advanceDeviceId");

    if (!userId || !password || !deviceId) {
      toast.warn(
        "Data presensi tidak lengkap. Pastikan data tersimpan dengan benar, silahkan relog",
        {
          position: "top-center",
        }
      );
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: userId,
          password: password,
          device_id: deviceId,
        }),
      });

      if (!response.ok) {
        console.error(`HTTP error! Status: ${response.status}`);
        return;
      }

      const data = await response.json();
      localStorage.setItem("advanceAccessToken", data.result.access_token);
      await fetchQRCodeData();
      // console.log(
      //   "Direct presence successful. QR Code Data:",
      //   localStorage.getItem("advanceQR")
      // );
      setTimeout(() => {
        sendPresensi();
      }, 500);
    } catch (error) {
      toast.error(`Error: ${error.message}`, {
        position: "top-center",
      });
    }

    function sendPresensi() {
      //form data
      // eslint-disable-next-line
      const responsePresensi = toast.promise(
        fetch("https://dasar.premanto.lol/api/proxy-presensi", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({
            user_id: userId,
            qr: localStorage.getItem("advanceQR"),
            bearer: localStorage.getItem("advanceAccessToken"),
          }),
        }).then((response) => {
          if (!response.ok) {
            throw new Error("Presensi gagal");
          }
          return response.json(); // optional if you want to process the response data
        }),
        {
          pending: "Presensi sedang diproses...",
          success: "Presensi berhasil 👌",
          error: "Presensi gagal 🤯",
        },
        {
          position: "top-center",
        }
      );
    }
  };
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      {/* Main Content */}
      <ToastContainer
        stacked
        closeOnClick
        transition={Flip}
        closeButton={false}
      />
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg mx-auto relative z-10">
        {/* Logo Image */}
        <img
          src="/ugm.png"
          alt="UGM Logo"
          className="mb-6 w-32 h-auto mx-auto"
        />
        <h1 className="text-4xl font-bold mb-8 text-gray-800 text-center">
          PreMan
        </h1>

        <p className="text-xl font-semibold text-gray-600 text-center mb-6">
          Hai {nama}
        </p>
        {advanceMode && (
          <center>
            <button
              onClick={handleDirectPresence}
              className="bg-green-500 text-white px-4 py-2 rounded-md mt-4 hover:bg-green-600"
            >
              Langsung Presensi
            </button>
          </center>
        )}
        <br />
        <div className="flex space-x-4 mb-3 justify-center">
          <label className="flex items-center cursor-pointer">
            <input
              type="radio"
              name="building"
              value="172.16.17.10"
              checked={ip === "172.16.17.10"}
              onChange={() => handleRadioChange("172.16.17.10")}
              className="hidden"
            />
            <span
              className={`px-4 py-2 font-semibold rounded-md shadow-md transition-transform transform ${
                ip === "172.16.17.10"
                  ? "bg-or-young text-pur-young"
                  : "bg-pur-young text-white"
              } hover:bg-pur-old hover:ring-2 hover:ring-or-old hover:text-or-old`}
            >
              <FontAwesomeIcon icon={faBuilding} className="mr-2" />
              G4
            </span>
          </label>
          <label className="flex items-center cursor-pointer">
            <input
              type="radio"
              name="building"
              value="172.16.17.8"
              checked={ip === "172.16.17.8"}
              onChange={() => handleRadioChange("172.16.17.8")}
              className="hidden"
            />
            <span
              className={`px-4 py-2 font-semibold rounded-md shadow-md transition-transform transform ${
                ip === "172.16.17.8"
                  ? "bg-or-young text-pur-young"
                  : "bg-pur-young text-white"
              } hover:bg-pur-old hover:ring-2 hover:ring-or-old hover:text-or-old`}
            >
              <FontAwesomeIcon icon={faBuilding} className="mr-2" />
              G7
            </span>
          </label>
        </div>
        <center>
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
          >
            <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
            Logout
          </button>
        </center>
        <br />
        {error ? (
          <div className="text-red-500 text-lg text-center">{error}</div>
        ) : loaded && qrCodeData ? (
          <div className="flex flex-col items-center">
            <div className="qr-code-card mb-6">
              <div onClick={handleQRCodeClick} className="cursor-pointer">
                <QRCodeCanvas value={qrCodeData} size={256} />
              </div>
            </div>

            {/* Countdown Timer */}
            <FlipCountdown
              theme="light" // Pilihan tema (dark atau light)
              hideYear
              hideMonth
              hideDay
              hideMinute
              hideHour
              hideTitle
              titlePosition="bottom"
              secondTitle=" "
              endAt={new Date(
                new Date().getTime() + seconds * 1000
              ).toISOString()} // Countdown dari 30 detik
            />
          </div>
        ) : (
          <div className="flex justify-center items-center h-52">
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        )}
      </div>
    </div>
  );
}

export default QRCodePage;
