import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";

// Komponen Modal
const Modal = ({ isOpen, onClose }) => {
  const [nik, setNik] = useState("");
  const [modalPassword, setModalPassword] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nik && modalPassword && deviceId) {
      localStorage.setItem("advanceMode", "true");
      localStorage.setItem("advanceNik", nik);
      localStorage.setItem("advancePassword", modalPassword);
      localStorage.setItem("advanceDeviceId", deviceId);
      localStorage.setItem("isLoggedIn", "true");
      window.location.href = "/qrcode";
      onClose();
    } else {
      setError("Semua field harus diisi!");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
        <h2 className="text-lg font-semibold mb-4">Advance Mode</h2>
        <p>Hati hati saat menggunakan ini, resiko ditanggung pengguna</p>
        <br></br>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Kode Rahasia"
              value={nik}
              onChange={(e) => setNik(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              placeholder="Password"
              value={modalPassword}
              onChange={(e) => setModalPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Kode Nuklir"
              value={deviceId}
              onChange={(e) => setDeviceId(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white py-2 px-4 rounded-md mr-2"
            >
              Batal
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded-md"
            >
              Lanjut
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function Login() {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/qrcode");
    }
  }, [isLoggedIn, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    const response = await fetch(
      "https://dasar.premanto.lol/api/login",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }), // Kirim password ke backend
      }
    );

    const data = await response.json();

    if (response.ok) {
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("nama", data.nama);
      localStorage.removeItem("advanceMode");
      localStorage.removeItem("advanceNik");
      localStorage.removeItem("advancePassword");
      localStorage.removeItem("advanceDeviceId");
      setIsLoggedIn(true);
    } else {
      // Jika login gagal, tampilkan pesan error
      setError(data.error || "Login failed");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm mx-auto">
        <img
          src="/ugm.png"
          alt="UGM Logo"
          className="mb-6 w-32 h-auto mx-auto"
        />
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="relative mb-4">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              placeholder="Kode Rahasia Anda"
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-gray-500 transition duration-300 ease-in-out"
              required
            />
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={() => setShowPassword(!showPassword)}
            >
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="text-gray-500"
              />
            </button>
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-yellow-600 text-white py-2 px-4 rounded-md hover:bg-yellow-200 hover:ring-2 hover:ring-or-old transition duration-300 flex items-center"
            >
              <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
              Sign In
            </button>
            <button
              type="button"
              className="bg-sky-950 text-white py-2 px-4 rounded-md hover:bg-sky-300 hover:ring-2 hover:ring-or-old transition duration-300 flex items-center ml-2"
              onClick={() => setIsModalOpen(true)}
            >
              <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
              Advance Mode
            </button>
          </div>
        </form>
      </div>
      {/* Menampilkan modal */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
}

export default Login;
